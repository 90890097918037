


.logo-wrapper {
    text-align: center;
    margin: 40px;
}

/* Roles */
.app-toggle-switch{
    width: 100%;
    height: 27px;
    text-align: center;
}

.app-toggle-switch input{
    width: 52px !important;
    height: 30px;
}

.app-switch-button{
    /*background: transparent;*/
    border-radius: 4px;
    border: none;
    margin: 5px;
}


.form-check-input:checked {
    background-color: #5AB00E !important;
    border-color: #5AB00E !important;
  }

.form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 216, 43, 0.25) !important;
  }

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
}