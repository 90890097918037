@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');



.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-login {
    margin: 40px;
}

.password-eye{
  background-color: #eee !important;
  border-left: thin solid !important;
  border-radius: 0px 4px 4px 0px !important;
}

.password-input{
  border-radius: 4px 0px 0px 4px !important;
}


.login-input label {
    font-size: 1.5rem;
    margin-bottom: 20px;
    width: 250px;
}

.login-input label p {
    margin: 0;
    line-height: normal;
}

.login-input label input {
    font-size: 1rem;
    min-width: 200px;
    font-family: Arial, sans-serif;
    padding-left: 5px;
}
  .login-button2{
    border: 1px solid #178fcf;
    background-color: #178fcf;
    width: 100%;
    padding: 15px;
    outline: none;
    color: #fff;
  }

  .login-text{
    /* font-family: 'Poppins';*/
    font-style: normal;
    font-weight: 600;
    font-size: 24px; 
    line-height: 36px;   
  }

  .login-input{
    border: none;
    background-color: #eee;
    width: 100%;
    padding: 0px;
    border-radius: 4px;
  }
  .login-footer-text{
    /* font-family: 'Poppins';*/
    font-style: normal;
    font-weight: 600;
    font-size: 16px; 
    max-width: 400px;
    line-height: 17px;
    text-align: center;   
  }